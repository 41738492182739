import React from "react";
import "./ProductCategoriesCard.css";
import { useNavigate } from "react-router-dom";

const ProductCategoriesCard = ({ category }) => {
  const navigate = useNavigate();
  const handleClick = (id) => {
    const currentPath = window.location.pathname;
    if (currentPath === "/b2b-home-page" || currentPath === "/") {
      navigate("/b2b-product-list", { state: { categoryId: id } });
    } else if (currentPath === "/b2c-home-page") {
      navigate("/b2c-product-list", { state: { categoryId: id } });
    }
  };

  return (
    <div
      className="product-cate-card"
      onClick={() => handleClick(category._id)}
    >
      <div className="product-cate-img-container">
        <img
          src={`data:image/png;base64,${category.image}`}
          className="product-cate-img"
          alt={category.name}
          loading="lazy"
        />
      </div>
      <div className="product-cate-title">{category.name}</div>
    </div>
  );
};

export default ProductCategoriesCard;
