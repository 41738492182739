import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "../../../Components/Button/Button";
import "./B2EProductPage.css";
import { useCart } from "../../../contexts/CartContext";
import { ClipLoader } from "react-spinners";

const B2EProductPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const { cart, addToCart, removeFromCart } = useCart();
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const [cartToggle, setCartToggle] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProduct = async () => {
      const token = localStorage.getItem("token");
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      try {
        const response = await axios.get(`${baseUrl}/api/product/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProduct(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  useEffect(() => {
    const isInCart = cart.some((item) => item._id === product?._id);
    setCartToggle(!isInCart);
  }, [cart, product]);

  const handleAddToCart = () => {
    addToCart({ ...product, quantity });
  };

  const handleRemoveFromCart = () => {
    removeFromCart(product);
  };

  const buyNow = () => {
    navigate("/b2c-cart-page");
  };

  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
  };

  if (!product) {
    return (
      <div className="loading-container">
        <ClipLoader color={"#11314C"} loading={loading} size={50} />
      </div>
    );
  }

  return (
    <div className="product-page-container">
      <div className="product-page-upper-container">
        <div className="flexx">
          <img
            src={
              currentImageIndex === 0 && product?.images?.featuredImage
                ? product?.images?.featuredImage
                : product?.images?.galleryImages[currentImageIndex - 1]
            }
            className="cat-card-img"
            alt="Product Image"
          />
          <div className="thumbnails">
            {product?.images?.featuredImage && (
              <img
                src={product?.images?.featuredImage}
                className={`thumbnail ${
                  currentImageIndex === 0 ? "active" : ""
                }`}
                onClick={() => setCurrentImageIndex(0)}
                alt="Featured Thumbnail"
              />
            )}
            {product?.images?.galleryImages.map((image, index) => (
              <img
                key={index}
                src={image}
                className={`thumbnail ${
                  currentImageIndex === index + 1 ? "active" : ""
                }`}
                onClick={() => handleThumbnailClick(index + 1)}
                alt={`Thumbnail ${index + 1}`}
              />
            ))}
          </div>
        </div>
        <div className="product-page-dis-container">
          <p>{product?.category?.name}</p>
          <h1>{product?.name}</h1>
          <hr />
          <h2>Description:</h2>
          <p>{product?.shortDis}</p>
          <table>
            <tbody>
              <tr>
                <th>SKU:</th>
                <td>{product?.sku}</td>
              </tr>
              <tr>
                <th>Category:</th>
                <td>{product?.category?.name}</td>
              </tr>
              <tr>
                <th>Brand:</th>
                <td>{product?.brand?.name}</td>
              </tr>
            </tbody>
          </table>
          <div className="quantity">
            <a
              href="#"
              className="quantity__minus"
              onClick={() => setQuantity((prev) => Math.max(1, prev - 1))}
            >
              <span>-</span>
            </a>
            <input
              name="quantity"
              type="text"
              className="quantity__input"
              value={quantity}
              onChange={(e) => setQuantity(Number(e.target.value))}
            />
            <a
              href="#"
              className="quantity__plus"
              onClick={() => setQuantity((prev) => prev + 1)}
            >
              <span>+</span>
            </a>
          </div>
          <div className="product-buy-now-button">
            <Button label="Buy Now" color="#73A9FA" action={buyNow} />
            {cartToggle ? (
              <Button
                label="Add to cart"
                color="#73A9FA"
                action={handleAddToCart}
              />
            ) : (
              <Button
                label="Remove from cart"
                color="#73A9FA"
                action={handleRemoveFromCart}
              />
            )}
          </div>
        </div>
      </div>
      <div className="product-page-lower-container">
        <h1 className="additional">Additional Information</h1>
        <div dangerouslySetInnerHTML={{ __html: product.additionalInfo }}></div>
      </div>
    </div>
  );
};

export default B2EProductPage;
