import React from "react";
import ProductCatalogueCardB2E from "../ProductCatalogueCardB2E/ProductCatalogueCardB2E";
import "./LatestArrivalsB2E"
const LatestArrivalsB2E = ({ color, products, loading }) => {
  const theme = color != "blue" ? "#EBA9D7" : "#73A9FA";
  return (
    <>
      <div className="latest-arrival-b2c-container">
        <div className="latest-arrival-b2c-title">Products Available</div>
        {
          loading ? (<div className="shimmer"></div>): (
            <div className="latest-arrival-b2c-card-container">
              {products.map((product) => (
                <ProductCatalogueCardB2E
                  key={product._id}
                  product={product}
                  color={theme}
                />
              ))}
            </div>
          )
        }
      </div>
    </>
  );
};

export default LatestArrivalsB2E;
