import React, { useContext, useEffect, useState } from "react";
import "./LatestArrivals.css";
import LatestArrCard from "../LatestArrCard/LatestArrCard";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ProductContext } from "../../contexts/ProductContext";
const LatestArrivals = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const token = localStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { searchTerm } = useContext(ProductContext);

  // Fetch products on page change
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const cachedProducts = JSON.parse(
          localStorage.getItem(`products_page_${page}`)
        );

        if (cachedProducts) {
          setProducts((prevProducts) => [...prevProducts, ...cachedProducts]);
        } else {
          const response = await axios.get(
            `${baseUrl}/api/product/latestproducts?prodType=B2B`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const newProducts = response.data.products;

          setProducts((prevProducts) => [...prevProducts, ...newProducts]);
          localStorage.setItem(
            `products_page_${page}`,
            JSON.stringify(newProducts)
          );
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [baseUrl, token, page]);

  useEffect(() => {
    const filtered = searchTerm
      ? products.filter((product) =>
          product.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : products;
    setFilteredProducts(filtered);
  }, [searchTerm, products]);

  return (
    <div className="latest-arrival-container">
      <div className="latest-arrival-title">Latest Arrivals</div>
      <div className="latest-arrival-card-container">
        {loading ? (
          <div className="shimmerLatestArrival"></div>
        ) : (
          filteredProducts.map((product) => (
            <LatestArrCard key={product._id} product={product} />
          ))
        )}
      </div>
    </div>
  );
};

export default LatestArrivals;
