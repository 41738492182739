import axios from "axios";
import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import "./AdminNewsLetter.css";

const AdminNewsLetter = () => {
  const [newsletters, setNewsletters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchNewsletters = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/letter/getnewsletter`);
        setNewsletters(response.data.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNewsletters();
  }, [baseUrl]);

  const exportToExcel = () => {
    if (newsletters.length === 0) {
      alert("No data to export");
      return;
    }

    const data = newsletters.map((newsletter) => ({
      Email: newsletter.email,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Newsletters");
    XLSX.writeFile(workbook, "Newsletters.xlsx");
  };

  if (loading) {
    return <div className="loading-message"></div>;
  }

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  return (
    <div className="newsletter-container">
      <h1>Newsletters</h1>
      <button onClick={exportToExcel} className="export-button">
        Export Emails to Excel
      </button>
      {newsletters.length > 0 ? (
        <ul>
          {newsletters.map((newsletter, index) => (
            <li key={index}>
              <p>Email: {newsletter.email}</p>
            </li>
          ))}
        </ul>
      ) : (
        <div className="no-newsletter-message">No newsletters available</div>
      )}
    </div>
  );
};

export default AdminNewsLetter;
