import React, { useEffect, useState } from "react";
import Button from "../../../Components/Button/Button";
import "./B2ECartPage.css";
import { useCart } from "../../../contexts/CartContext";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";

const B2ECartPage = () => {
  const { cart, removeFromCart, updateCartItem } = useCart();
  const [companyId, setCompanyId] = useState(null);
  const navigate = useNavigate();
  // Decode company token on component mount
  useEffect(() => {
    const companyToken = localStorage.getItem("companyToken");
    if (companyToken) {
      const decodedToken = jwtDecode(companyToken);
      setCompanyId(decodedToken.name); // Save company ID to state
    }
  }, []);

  const handleQuantityChange = (item, newQuantity) => {
    if (newQuantity > 0) {
      updateCartItem({ ...item, quantity: newQuantity });
    }
  };

  const getItemPrice = (item) => {
    if (item.priceByCompany && Array.isArray(item.priceByCompany)) {
      // Find the price object with the matching companyId
      const companyPriceObj = item.priceByCompany.find(
        (priceObj) => priceObj.companyId === companyId
      );
      
      // If a matching companyId is found, return its price, otherwise fall back to companyPrice
      if (companyPriceObj) {
        return Number(companyPriceObj.price);
      }
    }
    // Fall back to companyPrice if no match is found in priceByCompany
    return Number(item.companyPrice || 0);
  };
  

  const getTotalPrice = () => {
    return cart.reduce((total, item) => {
      const price = getItemPrice(item);
      return total + price * item.quantity;
    }, 0);
  };

  const shipping = () => {
    navigate("/b2e-shipping");
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  return (
    <div className="cart-container">
      <p className="cart-heading">Your cart</p>
      {cart.length === 0 ? (
        <p className="empty-cart-heading">Your cart is empty</p>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th>SKU</th>
                <th>Product</th>
                <th style={{ width: "40vw" }}>Product Description</th>
                <th>Price</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {cart.map((item) => (
                <tr key={item.id}>
                  <td>
                    <div className="table-data">
                      <i
                        className="fa-regular fa-circle-xmark"
                        onClick={() => removeFromCart(item)}
                      ></i>
                      <p>{item.sku}</p>
                      <img
                        src={`${item?.images?.featuredImage}`}
                        className="cat-card-img"
                        alt={item.name}
                      />
                    </div>
                  </td>
                  <td
                    onClick={() => {
                      navigate(`/b2e-product-page/${item._id}`);
                    }}
                  >
                    <p>{truncateText(item.name, 20)}</p>
                  </td>
                  <td>
                    <p>{truncateText(item.shortDis, 20)}</p>
                  </td>
                  <td>
                    <p>₹{getItemPrice(item)}</p>
                  </td>
                  <td>
                    <div className="table-data">
                      <i
                        className="fa-solid fa-angle-left"
                        onClick={() => handleQuantityChange(item, item.quantity - 1)}
                      ></i>
                      <p>{item.quantity}</p>
                      <i
                        className="fa-solid fa-angle-right"
                        onClick={() => handleQuantityChange(item, item.quantity + 1)}
                      ></i>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr className="cart-line" />
          <table className="total-table">
            <tbody>
              <tr>
                <td>
                  <p style={{ margin: "0" }}>Your total:</p>
                  <p style={{ margin: "0" }}>({cart.length} items)</p>
                </td>
                <td>₹{getTotalPrice()}</td>
              </tr>
            </tbody>
          </table>
          <div className="cart-button">
            <Button
              label="Proceed to checkout"
              color="#73A9FA"
              action={shipping}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default B2ECartPage;
