import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../contexts/CartContext";
import "./ProductCatalogueCardB2C.css";

const ProductCatalogueCardB2C = ({ color, product }) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const [cartToggle, setCartToggle] = useState(true);
  const { cart, addToCart, removeFromCart } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    const isInCart = cart.some((item) => item._id === product?._id);
    setCartToggle(!isInCart);
  }, [cart, product]);

  const handleAddToCart = () => {
    addToCart({ ...product, quantity: 1 });
  };

  const handleRemoveFromCart = () => {
    removeFromCart(product);
  };

  const truncateText = (text, charLimit = 20) => {
    if (!text) return "";
    return text.length > charLimit ? `${text.slice(0, charLimit)}...` : text;
  };

  return (
    <div className="cat-b2c-card">
      <div className="cat-b2c-img-container">
        <img
          src={`${product?.images?.featuredImage}`}
          className="cat-b2c-card-img"
          onClick={() => {
            navigate(`/b2c-product-page/${product?._id}`);
          }}
        ></img>
      </div>
      {cartToggle ? (
        <button
          className="cat-b2c-button"
          style={{ backgroundColor: `${color}` }}
          onClick={handleAddToCart}
        >
          Add to Cart
        </button>
      ) : (
        <button
          className="cat-b2c-button"
          style={{ backgroundColor: `${color}` }}
          onClick={handleRemoveFromCart}
        >
          Remove from Cart
        </button>
      )}
      <div className="cat-b2c-title-container">
        <div>
          <p className="cat-b2c-pro-sub-title">{product?.name}</p>
          <div className="cat-b2c">
            <p className="cat-b2c-pro-title">
              {truncateText(product?.shortDis)}
            </p>
            <p className="cat-b2c-pro-price">₹{product?.standardPrice}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCatalogueCardB2C;
