import React, { useContext, useEffect, useState } from "react";
import Baner from "../../../Components/Baner/Baner";
import ProductCategories from "../../../Components/ProductCategories/ProductCategories";
import Testimonials from "../../../Components/Testimonials/Testimonials";
import GetInTouch from "../../../Components/GetInTouch/GetInTouch";
import LatestArrivalsB2C from "../../../Components/LatestArrivalsB2C/LatestArrivalsB2C";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ProductContext } from "../../../contexts/ProductContext";
import OurClient from "../../../Components/OurClient/OurClient";

const B2CHomePage = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(1);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { searchTerm } = useContext(ProductContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const cachedProducts = JSON.parse(
          localStorage.getItem(`products_page_${page}`)
        );

        if (cachedProducts) {
          setProducts((prevProducts) => [...prevProducts, ...cachedProducts]);
        } else {
          const response = await axios.get(
            `${baseUrl}/api/product/latestproducts?prodType=B2C`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const newProducts = response.data.products;
          setProducts(response.data.products);
          // setProducts((prevProducts) => [...prevProducts, ...newProducts]);
          localStorage.setItem(
            `products_page_${page}`,
            JSON.stringify(newProducts)
          );
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [baseUrl, token, page]);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/banner/getbanner`);
        setBannerData(response.data);
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };

    fetchBannerData();
  }, [baseUrl]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/brand`)
      .then((res) => res.json())
      .then((data) => {
        if (data.mode) {
          setBannerData([]);
          setCategories([]);
        }
      })
      .catch((err) => console.error("Error fetching brand data:", err));
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filtered = products.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products);
    }
  }, [searchTerm, products]);

  return (
    <>
      <Baner bannerData={bannerData} />
      <LatestArrivalsB2C color="blue" products={filteredProducts} />
      <ProductCategories />
      <Testimonials color="blue" />
      <GetInTouch color="blue" />
      <OurClient />
    </>
  );
};

export default B2CHomePage;
