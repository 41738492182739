import React, { useEffect, useState, useContext } from "react";
import Baner from "../../../Components/Baner/Baner";
import ProductCategories from "../../../Components/ProductCategories/ProductCategories";
import LatestArrivalsB2E from "../../../Components/LatestArrivalsB2E/LatestArrivalsB2E";
import Testimonials from "../../../Components/Testimonials/Testimonials";
import GetInTouch from "../../../Components/GetInTouch/GetInTouch";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { ProductContext } from "../../../contexts/ProductContext";
import OurClient from "../../../Components/OurClient/OurClient";

const B2EHomePage = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState("");
  const [companyId, setCompanyId] = useState("");
  const token = localStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const companyToken = localStorage.getItem("companyToken");
    if (companyToken) {
      const decodedToken = jwtDecode(companyToken);
      setCompanyId(decodedToken.name);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("companyToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      setCompany(decodedToken.id);
      if (decodedToken.role !== "company") {
        navigate("/b2e-login");
      }
    } else {
      navigate("/b2e-login");
    }
  }, []);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseUrl}/api/banner/getbanner`);
        setBannerData(response.data);
      } catch (error) {
        console.error("Error fetching banners:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBannerData();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${baseUrl}/api/product/empproducts?empId=${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const filteredProduct = response.data.products.filter((item) =>
          item.productType.includes("B2E")
        );
        setProducts(filteredProduct);
        setFilteredProducts(filteredProduct);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [baseUrl, token, company]);

  console.log(products);

  return (
    <>
      <Baner label="Welcome to prima gifting" bannerData={bannerData} />
      <LatestArrivalsB2E
        color="blue"
        products={filteredProducts}
        loading={loading}
      />
      <GetInTouch color="blue" />
      <OurClient />
    </>
  );
};

export default B2EHomePage;
