import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuote } from "../../contexts/QuoteContext";
import "./LatestArrCard.css";

const LatestArrCard = ({ product }) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const [quoteToggle, setQuoteToggle] = useState(true);
  const { quote, addQuote, removeQuote } = useQuote();
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const isInQuote = quote.some((item) => item._id === product?._id);
    setQuoteToggle(!isInQuote);
  }, [quote, product]);

  const handleAddToQuote = () => {
    addQuote({ ...product, quantity });
  };

  const handleRemoveFromQuote = () => {
    removeQuote(product);
  };

  const trunc = (text) => {
    if (text.length > 30) {
      const truncText = text.slice(0, 30);
      return truncText;
    } else {
      return text;
    }
  };
  return (
    <div className="lat-arr-card-container">
      <div className="lat-arr-img-container">
        <img
          src={`${product?.images.featuredImage}`}
          className="lat-arr-img"
          alt="Sample"
          onClick={() => navigate(`/b2b-product-page/${product?._id}`)}
        />
        {quoteToggle ? (
          <button className="lat-arr-button" onClick={handleAddToQuote}>
            Quote List
          </button>
        ) : (
          <button className="lat-arr-button" onClick={handleRemoveFromQuote}>
            Remove from quote
          </button>
        )}
      </div>
      <div className="lat-arr-title">{trunc(product.name)}</div>
      <div className="lat-arr-sub-title">{trunc(product.shortDis)}</div>
    </div>
  );
};

export default LatestArrCard;
