import React, { useEffect, useState } from "react";
import ProductCategoriesCard from "../ProductCategoriesCard/ProductCategoriesCard";
import "./ProductCategories.css";
import axios from "axios";

const ProductCategories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getCategories = () => {
      const cachedCategories = localStorage.getItem("categories");
      if (cachedCategories) {
        setCategories(JSON.parse(cachedCategories));
      } else {
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/api/category`)
          .then((res) => {
            setCategories(res.data);
            localStorage.setItem("categories", JSON.stringify(res.data));
          })
          .catch((error) => console.log(error));
      }
    };

    getCategories();
  }, []);

  return (
    <div className="prod-categ-container">
      <div className="prod-categ-title">Product Categories</div>
      <div className="prod-categ-card-container">
        {categories.map((category) => (
          <ProductCategoriesCard key={category._id} category={category} />
        ))}
      </div>
    </div>
  );
};

export default ProductCategories;
