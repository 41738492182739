import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "./AdminOrderDetails.css";
import { ClipLoader } from "react-spinners";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AdminCompanyOrderDetails = () => {
  const { id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const isCompanyOrder = queryParams.get("isCompanyOrder") === "true";
  const [order, setOrder] = useState(null);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailContent, setEmailContent] = useState("");
  const token = localStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const defaultEmailContent = `
    <div style="font-family: Arial, sans-serif; color: #333;">
      <h2>Order Update</h2>
      <p>Your order status has been updated to: <strong>\${status}</strong></p>
      <p>Thank you for shopping with us!</p>
    </div>
  `;

  useEffect(() => {
    const fetchOrderDetails = async () => {
      setLoading(true);
      try {
        const url = isCompanyOrder
          ? `${baseUrl}/api/company-order/${id}`
          : `${baseUrl}/api/order/${id}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrder(response.data);
        setStatus(response.data?.status);
        setEmailContent(
          defaultEmailContent.replace("${status}", response.data?.status)
        );
      } catch (error) {
        console.error("Error fetching order details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [id, token, baseUrl]);

  const handleSaveTrackingNumber = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${baseUrl}/api/otp/send-update`,
        {
          email: order.email,
          content: emailContent,
          id,
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success("Updated successfully.");
      navigate(`/orders`);
    } catch (error) {
      console.error("Error updating tracking number:", error);
      toast.error("Failed to update tracking number.");
    }
  };

  if (loading) {
    return (
      <div className="loader-container" style={{ width: "100%" }}>
        <ClipLoader size={30} color={"#000"} loading={loading} />
      </div>
    );
  }

  if (!order) {
    return (
      <div className="loader-container" style={{ width: "100%" }}>
        <h3>No Data found</h3>
      </div>
    );
  }

  return (
    <div className="order-details-container">
      <ToastContainer />
      <h2 className="heading">Order Details</h2>
      <div className="order-info">
        <div className="order-customer-info">
          <p className="info-item">
            <span className="info-label">Customer Name:</span> {order?.name}
          </p>
          <p className="info-item">
            <span className="info-label">Email:</span> {order?.email}
          </p>
          <p className="info-item">
            <span className="info-label">Contact Number:</span> {order?.number}
          </p>
          <p className="info-item">
            <span className="info-label">Billing Address:</span>{" "}
            {order?.billingAddress}
          </p>
          <p className="info-item">
            <span className="info-label">Shipping Address:</span>{" "}
            {order?.shippingAddress}
          </p>
          <p className="info-item">
            <span className="info-label">Date:</span>{" "}
            {new Date(order?.orderedAt).toLocaleDateString()}
          </p>
          <p className="info-item">
            <span className="info-label">Total Price:</span> ₹{order?.total}
          </p>
        </div>

        <div className="order-products">
          <h3 className="subheading">Products</h3>
          {order?.cart.map((item, index) => (
            <div key={index} className="product-item">
              <p>
                <span className="info-label">Product Name:</span> {item?.name}
              </p>
              <p>
                <span className="info-label">Quantity:</span> {item?.quantity}
              </p>
              <p>
                <span className="info-label">Price per Unit:</span> ₹
                {item?.price}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="editable-fields">
        <div className="tracking-status-container">
          <label>
            <span className="info-label">Status:</span>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="status-select"
            >
              <option value="Ordered">Ordered</option>
              <option value="Out for Delivery">Out for Delivery</option>
              <option value="Delivered">Delivered</option>
            </select>
          </label>
        </div>
        <div className="email-editor" style={{ minWidth: "600px" }}>
          <h3>Edit Email Template</h3>
          <ReactQuill
            theme="snow"
            value={emailContent}
            onChange={setEmailContent}
          />
        </div>
        <button
          onClick={handleSaveTrackingNumber}
          className="save-tracking-button"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AdminCompanyOrderDetails;
