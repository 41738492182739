/**
 * @title Prima
 * @description This file serves as the main entry point for the React application. It wraps the entire app in necessary context providers, such as `CartProvider` and `QuoteProvider`, and sets up routing using `react-router-dom`. The rendered content is mounted to the root HTML element.
 * @author [Your Name]
 * @date [Creation Date]
 */

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { CartProvider } from "./contexts/CartContext";
import { QuoteProvider } from "./contexts/QuoteContext";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <CartProvider>
        <QuoteProvider>
          <App />
        </QuoteProvider>
      </CartProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
