import React, { useState } from "react";
import Button from "../Button/Button";
import "./GetInTouch.css";
import { toast, ToastContainer } from "react-toastify";

const GetInTouch = ({ color }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    location: "",
    message: "",
    phoneNumber: "",
  });
  const [loading, setLoading] = useState(false);

  const theme = color !== "blue" ? "#FFE8F2" : "#D1E4FF";

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    setLoading(true); // Start loading
    try {
      const response = await fetch(`${baseUrl}/api/consult/consulation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      setFormData({
        name: "",
        email: "",
        companyName: "",
        location: "",
        message: "",
        phoneNumber: "",
      });
      if (response.ok) {
        toast.success("Submitted successfully");
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="get-in-touch-comp-container">
      <ToastContainer />
      <p
        className="get-in-touch-comp-header"
        style={{ textAlign: "center", marginTop: "150px" }}
      >
        Let’s Personalize Gifting Solutions
        <br /> For Your Business
      </p>
      <div className="get-in-touch-comp-sub-container">
        <div className="get-in-touch-comp-dis">
          <p className="get-in-touch-comp-title" style={{ fontSize: "30px" }}>
            Get in touch today and send us a message to discuss your specific
            needs.
          </p>
          <p className="get-in-touch-comp-sub-title">
            Collaborate with our experts to design unique, thoughtful gifting
            experience tailored to your brand. Let us help you select the
            perfect gifts that elevate your corporate image.
          </p>
        </div>
        <div className="get-in-touch-comp-form-container">
          <form onSubmit={handleSubmit}>
            <div className="get-in-touch-comp-field-container">
              <div className="get-in-touch-comp-row">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  style={{ backgroundColor: `${theme}` }}
                  disabled={loading} // Disable input when loading
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  style={{ backgroundColor: `${theme}` }}
                  disabled={loading} // Disable input when loading
                />
              </div>
              <div className="get-in-touch-comp-row">
                <input
                  type="text"
                  name="companyName"
                  placeholder="Company Name"
                  value={formData.companyName}
                  onChange={handleChange}
                  style={{ backgroundColor: `${theme}` }}
                  disabled={loading} // Disable input when loading
                />
                <input
                  type="text"
                  name="location"
                  placeholder="Location"
                  value={formData.location}
                  onChange={handleChange}
                  style={{ backgroundColor: `${theme}` }}
                  disabled={loading} // Disable input when loading
                />
              </div>
              <div
                className="get-in-touch-comp-row"
                style={{ height: "6.5vh" }}
              >
                <input
                  type="number"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  style={{ backgroundColor: `${theme}`, width: "95%" }}
                  disabled={loading}
                />
              </div>
              <div className="get-in-touch-comp-row" style={{ height: "12vh" }}>
                <textarea
                  name="message"
                  placeholder="Message...."
                  value={formData.message}
                  onChange={handleChange}
                  style={{
                    backgroundColor: theme,
                    resize: "none",
                  }}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="get-in-touch-comp-button-container"></div>
            <button type="submit" className="send-btn" disabled={loading}>
              {loading ? (
                <div className="loader"></div>
              ) : (
                <Button
                  label="Send"
                  color={color !== "blue" ? "#EBA9D7" : "#73A9FA"}
                  type="submit"
                  className="send-btnn"
                />
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
