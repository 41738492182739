import React, { useState, useEffect } from "react";
import axios from "axios";
import ProductCatalogueCardB2E from "../../../Components/ProductCatalogueCardB2E/ProductCatalogueCardB2E";
import ProductCatalogueCardB2C from "../../../Components/ProductCatalogueCardB2C/ProductCatalogueCardB2C";
import { jwtDecode } from "jwt-decode";
import "./ByPrice.css";

function ByPrice() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [company, setCompany] = useState("");
  const [isB2B, setIsB2B] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(9);
  const [productCache, setProductCache] = useState({});
  const [totalPages, setTotalPages] = useState(1);

  const [priceRanges, setPriceRanges] = useState({
    range1: false,
    range2: false,
    range3: false,
    range4: false,
  });
  const [maxPrice, setMaxPrice] = useState(Infinity);

  useEffect(() => {
    const companyToken = localStorage.getItem("companyToken");
    const userToken = localStorage.getItem("token");

    if (companyToken) {
      const decodedToken = jwtDecode(companyToken);
      setCompany(decodedToken.id);
      setIsB2B(true);
    } else if (userToken) {
      setIsB2B(false);
    }
  }, []);

  // Fetch products from API or cache
  useEffect(() => {
    const fetchProducts = async () => {
      const cacheKey = `${isB2B}-${company}`;

      if (productCache[cacheKey]) {
        console.log("Using cached data...");
        const cachedData = productCache[cacheKey];
        setProducts(cachedData.products);
        setFilteredProducts(cachedData.products);
        setTotalPages(cachedData.totalPages);
        return;
      }

      try {
        setLoading(true);
        setError(null);
        const token = localStorage.getItem(isB2B ? "companyToken" : "token");

        const { data } = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/product/latestproducts`,
          {
            params: { prodType: isB2B ? "B2B" : "B2C", page: currentPage },
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const newCache = {
          products: data.products,
          totalPages: data.totalPages,
          totalProducts: data.totalProducts,
        };

        setProductCache((prevCache) => ({
          ...prevCache,
          [cacheKey]: newCache,
        }));
        setProducts(data.products);
        setFilteredProducts(data.products);
        setTotalPages(data.totalPages);
      } catch (err) {
        console.error("Error fetching products:", err);
        setError("Failed to load products. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [isB2B, company, currentPage, productCache]);

  // Apply filters and sorting
  useEffect(() => {
    const applyFilters = () => {
      const getPrice = (product) =>
        isB2B
          ? product.priceByCompany.find((entry) => entry.companyId === company)
              ?.price || 0
          : product.standardPrice || 0;

      const matchesRange = (price) =>
        (priceRanges.range1 && price < 500) ||
        (priceRanges.range2 && price >= 500 && price <= 1000) ||
        (priceRanges.range3 && price > 1000 && price <= 1500) ||
        (priceRanges.range4 && price > 1500) ||
        (!priceRanges.range1 &&
          !priceRanges.range2 &&
          !priceRanges.range3 &&
          !priceRanges.range4);

      const filtered = products
        .filter((product) => {
          const price = getPrice(product);
          return matchesRange(price) && price <= maxPrice;
        })
        .sort((a, b) => {
          const priceA = getPrice(a);
          const priceB = getPrice(b);
          return sortOrder === "asc" ? priceA - priceB : priceB - priceA;
        });

      setFilteredProducts(filtered);
      setTotalPages(Math.ceil(filtered.length / productsPerPage));
    };

    applyFilters();
  }, [
    products,
    priceRanges,
    maxPrice,
    sortOrder,
    isB2B,
    company,
    productsPerPage,
  ]);
  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handlePriceRangeChange = (range) => {
    setPriceRanges((prevRanges) => ({
      ...prevRanges,
      [range]: !prevRanges[range],
    }));
  };

  const handleSliderChange = (event) => {
    setMaxPrice(parseInt(event.target.value, 10));
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const renderPagination = () => {
    const pages = [];

    pages.push(
      <button key="prev" onClick={handlePrev} disabled={currentPage === 1}>
        Prev
      </button>
    );

    if (currentPage > 1) {
      pages.push(
        <button
          key={currentPage - 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          {currentPage - 1}
        </button>
      );
    }

    pages.push(
      <button key={currentPage} className="active">
        {currentPage}
      </button>
    );

    if (currentPage < totalPages) {
      pages.push(
        <button
          key={currentPage + 1}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          {currentPage + 1}
        </button>
      );
    }

    pages.push(
      <button
        key="next"
        onClick={handleNext}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    );

    return pages;
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  return (
    <div>
      <div className="sort-container">
        <label htmlFor="sortOrder">Sort by Price: </label>
        <select id="sortOrder" value={sortOrder} onChange={handleSortChange}>
          <option value="asc">Low to High</option>
          <option value="desc">High to Low</option>
        </select>
      </div>

      <div className="flex-container">
        <div>
          <div className="price-filter-container">
            <label>
              <input
                type="checkbox"
                checked={priceRanges.range1}
                onChange={() => handlePriceRangeChange("range1")}
              />
              &lt; 500
            </label>
            <label>
              <input
                type="checkbox"
                checked={priceRanges.range2}
                onChange={() => handlePriceRangeChange("range2")}
              />
              500 - 1000
            </label>
            <label>
              <input
                type="checkbox"
                checked={priceRanges.range3}
                onChange={() => handlePriceRangeChange("range3")}
              />
              1000 - 1500
            </label>
            <label>
              <input
                type="checkbox"
                checked={priceRanges.range4}
                onChange={() => handlePriceRangeChange("range4")}
              />
              1500 +
            </label>
          </div>

          <div className="slider-container">
            <label htmlFor="priceSlider">Max Price: {maxPrice}</label>
            <input
              id="priceSlider"
              type="range"
              min="500"
              max="10000"
              step="100"
              value={maxPrice}
              onChange={handleSliderChange}
            />
          </div>
        </div>

        <div className="latest-arrival-b2c-container">
          <div className="latest-arrival-b2c-titles">Latest Arrivals</div>
          {loading ? (
            <div className="shimmerr"></div>
          ) : error ? (
            <div className="error-message">{error}</div>
          ) : currentProducts.length === 0 ? (
            <div className="no-products-message">
              No products found in this price range.
            </div>
          ) : (
            <div className="latest-arrival-b2c-card-container">
              {currentProducts.map((product) =>
                isB2B ? (
                  <ProductCatalogueCardB2E
                    key={product._id}
                    product={product}
                    color="#429bf5"
                    company={company}
                  />
                ) : (
                  <ProductCatalogueCardB2C
                    key={product._id}
                    product={product}
                    color="#429bf5"
                  />
                )
              )}
            </div>
          )}
          <div className="pagination">{renderPagination()}</div>
        </div>
      </div>
    </div>
  );
}

export default ByPrice;
