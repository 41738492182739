import React, { useEffect, useState } from "react";
import axios from "axios";
import "./AdminConsultation.css";

const AdminConsultation = () => {
  const [consultation, setConsultation] = useState([]);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/consult/getconsulation`
        );
        setConsultation(response.data.data);
      } catch (error) {
        console.error("Error fetching quotes:", error);
      }
    };

    fetchQuotes();
  }, []);

  return (
    <div className="quotes-table-container">
      <h2>Get in Touch</h2>
      <table className="quotes-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Company Name</th>
            <th>Phone Number</th>
            <th>Location</th>
            <th>Email</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {consultation.map((quote) => (
            <tr key={quote._id}>
              <td>{quote.name}</td>
              <td>{quote.companyName}</td>
              <td>{quote.phoneNumber}</td>
              <td>{quote.location}</td>
              <td>{quote.email}</td>
              <td>{quote.message}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminConsultation;
