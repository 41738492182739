import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./B2CProductList.css";
import axios from "axios";
import ProductCatalogueCardB2C from "../../../Components/ProductCatalogueCardB2C/ProductCatalogueCardB2C";
import ClipLoader from "react-spinners/ClipLoader";

const B2CProductList = () => {
  const location = useLocation();
  const categoryId = location.state?.categoryId;
  const [products, setProducts] = useState([]);
  const [sortOrder, setSortOrder] = useState("latest");
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(9);
  const [totalProducts, setTotalProducts] = useState(10);
  const [totalPages, setTotalPages] = useState(5);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [selectedCatId, setSelectedCatId] = useState(
    categoryId || "673c6a8ed6746ea055f1183d"
  );
  const [productCache, setProductCache] = useState({});
  let selectedCategoryName = location.state?.categoryName || "";

  const categories = [
    {
      _id: "67067e86dcb95f97fdf03c8f",
      name: "IT assets & Peripherials",
    },
    {
      _id: "673c6a8ed6746ea055f1183d",
      name: "Home Appliances",
    },
    {
      _id: "673c6b42d6746ea055f11843",
      name: "Computer / Laptop Accessories",
    },
    {
      _id: "673c6bdad6746ea055f11861",
      name: "Smart Watches",
    },
    {
      _id: "673c6cc0d6746ea055f118ae",
      name: "Awards and Rewards",
    },
    {
      _id: "673c6d60d6746ea055f118b8",
      name: "Gift Cards & Vouchers",
    },
    {
      _id: "673c6e6bd6746ea055f118c2",
      name: "Sports & Fitness",
    },
    {
      _id: "673c6f99d6746ea055f118cc",
      name: "Diaries / Notebooks / Organisers",
    },
    {
      _id: "673c7073d6746ea055f118dd",
      name: "Earbuds, Headphones, Neckband, Headsets & Earphones",
    },
    {
      _id: "673c75b5d6746ea055f1193d",
      name: "Mobile Device/ Tablets",
    },
    {
      _id: "673c7b1ed6746ea055f11a29",
      name: "Caps , Shirts & Tshirts",
    },
    {
      _id: "673c7f99d6746ea055f11a83",
      name: "Laptops",
    },
    {
      _id: "673c87ecd6746ea055f11b09",
      name: "Powerbanks",
    },
    {
      _id: "673c88cfd6746ea055f11b16",
      name: "Speaker & Sound Bar",
    },
    {
      _id: "673c8a89d6746ea055f11b37",
      name: "Bedsheets, Dohar, Towels",
    },
    {
      _id: "6741bda0d6746ea055f11dc9",
      name: "Audio and Video Solutions",
    },
    {
      _id: "6741c816d6746ea055f11f61",
      name: "Chocolates, Tea and Cookies",
    },
    {
      _id: "675d49e1e2d4b6d23a7277fc",
      name: "Clocks and watches",
    },
    {
      _id: "6761a260e2d4b6d23a7285cb",
      name: "Candels ,Diyas and Aromas",
    },
  ];

  useEffect(() => {
    fetchProducts(selectedCatId, currentPage);
  }, [selectedCatId, currentPage]);

  const fetchProducts = async (category, page) => {
    const cacheKey = `${category}-${page}`;

    if (productCache[cacheKey]) {
      setProducts(productCache[cacheKey].products);
      // setTotalPages(productCache[cacheKey].totalPages);
      // setTotalProducts(productCache[cacheKey].totalProducts);
      return;
    }

    setLoading(true);
    const token = localStorage.getItem("token");
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const config = {
      method: "get",
      url: `${baseUrl}/api/product/cat?cat=${selectedCatId}&page=${page}&prodType=B2B`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(config);
      setProducts(response.data.products);
      setTotalPages(response.data.totalPages);
      setTotalProducts(response.data.totalProducts);

      setProductCache((prevCache) => ({
        ...prevCache,
        [cacheKey]: {
          products: response.data.products,
          totalPages: response.data.totalPages,
          totalProducts: response.data.totalProducts,
        },
      }));
    } catch (error) {
      console.log("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  const selectCat = (category) => {
    setSelectedCatId(category);
    setCurrentPage(1);
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  // const sortedProducts = products.sort((a, b) => {
  //   if (sortOrder !== "latest") {
  //     return new Date(b.createdAt) - new Date(a.createdAt);
  //   } else {
  //     return new Date(a.createdAt) - new Date(b.createdAt);
  //   }
  // });

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  // const totalPages = Math.ceil(products.length / productsPerPage);

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const renderPagination = () => {
    const pages = [];

    pages.push(
      <button key="prev" onClick={handlePrev} disabled={currentPage === 1}>
        Prev
      </button>
    );

    if (currentPage > 1) {
      pages.push(
        <button
          key={currentPage - 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          {currentPage - 1}
        </button>
      );
    }

    pages.push(
      <button key={currentPage} className="active">
        {currentPage}
      </button>
    );

    if (currentPage < totalPages) {
      pages.push(
        <button
          key={currentPage + 1}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          {currentPage + 1}
        </button>
      );
    }

    pages.push(
      <button
        key="next"
        onClick={handleNext}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    );

    return pages;
  };

  return (
    <div className="product-list-container">
      <div className="product-list-title">Product catalogue</div>
      <div className="product-list-showcase">
        <div className="product-list-cat-list-container">
          <h1>Categories</h1>
          {categories.map((cat) => (
            <p
              key={cat._id}
              onClick={() => selectCat(cat._id)}
              className={
                cat._id === selectedCatId ||
                (cat.name === selectedCatId && !selectedCatId)
                  ? "selected-category"
                  : ""
              }
            >
              {cat.name}
            </p>
          ))}
        </div>
        <div className="product-list-cat-container">
          <div className="product-list-selection">
            <select onChange={handleSortOrderChange} value={sortOrder}>
              <option value="latest">Latest</option>
              <option value="oldest">Oldest</option>
            </select>
            <p className="pagination-text">
              Showing {indexOfFirstProduct + 1} to{" "}
              {Math.min(indexOfLastProduct, totalProducts)} of {totalProducts}{" "}
              results
            </p>
          </div>
          <div className="product-list-card-container">
            {loading ? (
              <div className="loader-b2b">
                <ClipLoader color={"#000"} loading={loading} size={30} />
              </div>
            ) : products.length === 0 ? (
              <div className="no-data">No Data Found</div>
            ) : (
              products.map((product) => (
                <ProductCatalogueCardB2C key={product._id} product={product} />
              ))
            )}
          </div>
          <div className="pagination">{renderPagination()}</div>
        </div>
      </div>
    </div>
  );
};

export default B2CProductList;
