import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./AdminProducts.css";

const AdminProducts = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("all");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(9);
  const [totalPages, setTotalPages] = useState(1);
  const [totalProducts, setTotalProducts] = useState(1);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const token = localStorage.getItem("token");
        const baseUrl = process.env.REACT_APP_API_BASE_URL;

        const config = {
          method: "get",
          url: `${baseUrl}/api/brand`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios(config);
        console.log(response.data);
        setBrands(response.data);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchBrands();
  }, []);

  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem("token");
      const baseUrl = process.env.REACT_APP_API_BASE_URL;

      const config = {
        method: "get",
        url: `${baseUrl}/api/product?page=${currentPage}&limit=10&brand=${selectedBrand}&search=${searchTerm}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      setProducts(response.data.products);
      setTotalPages(response.data.totalPages);
      setTotalProducts(response.data.totalProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [currentPage, selectedBrand, searchTerm]);

  const addProduct = () => {
    navigate("/add-product");
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEditProduct = (productId) => {
    navigate(`/edit-product/${productId}`);
  };

  const handleDeleteProduct = async (productId) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const baseUrl = process.env.REACT_APP_API_BASE_URL;

      const config = {
        method: "delete",
        url: `${baseUrl}/api/product/${productId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios(config);
      setProducts((prevProducts) =>
        prevProducts.filter((product) => product.id !== productId)
      );
      fetchProducts();
    } catch (error) {
      console.error("Error deleting product:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/product`)
      .then((res) => res.json())
      .then((data) => {
        if (data.mode) {
          setProducts(null);
        }
      })
      .catch((err) => console.error("Error fetching product:", err));
    setLoading(false);
  }, [currentPage]);


  const filteredProducts = products.filter((product) => {
    const matchesSearch = product.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesBrand =
      selectedBrand === "all" || product.brand?._id === selectedBrand;

    return matchesSearch && matchesBrand;
  });

  const handleBrandChange = (e) => {
    setSelectedBrand(e.target.value);
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const renderPagination = () => {
    const pages = [];

    pages.push(
      <button key="prev" onClick={handlePrev} disabled={currentPage === 1}>
        Prev
      </button>
    );

    if (currentPage > 1) {
      pages.push(
        <button
          key={currentPage - 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          {currentPage - 1}
        </button>
      );
    }

    pages.push(
      <button key={currentPage} className="active">
        {currentPage}
      </button>
    );

    if (currentPage < totalPages) {
      pages.push(
        <button
          key={currentPage + 1}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          {currentPage + 1}
        </button>
      );
    }

    pages.push(
      <button
        key="next"
        onClick={handleNext}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    );

    return pages;
  };

  return (
    <div className="admin-products-container">
      <div className="admin-product-header">
        <div className="admin-product-header-left">
          <div className="brand-dropdown-container">
            <select
              value={selectedBrand}
              onChange={handleBrandChange}
              className="brand-dropdown"
            >
              <option value="all">All Products</option>
              {brands.map((brand) => (
                <option key={brand._id} value={brand._id}>
                  {brand.name}
                </option>
              ))}
            </select>
          </div>
          <button onClick={addProduct}>Add New</button>
        </div>
        <div className="admin-product-header-right">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="admin-search-input"
          />
          <button className="admin-search-button">
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
      <div className="admin-product-table-container">
        {loading ? (
          <>
            <div className="shimmer"></div>
          </>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>SKU</th>
                <th>Stock</th>
                <th>Price</th>
                <th>Category</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.map((product) => {
                const imageUrl = product?.images
                  ? `${process.env.REACT_APP_API_BASE_URL}/path/to/images/${product.images}`
                  : "/assets/product.svg";

                return (
                  <tr key={product?._id}>
                    <td>
                      <img
                        src={`${product?.images?.featuredImage}`}
                        alt="Featured"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/assets/product.svg";
                        }}
                      />
                    </td>
                    <td>{product?.name}</td>
                    <td>{product?.sku}</td>
                    <td>{product?.stock}</td>
                    <td>₹{product?.standardPrice}</td>
                    <td>{product?.category?.name}</td>
                    <td>
                      <i
                        className="fa-solid fa-pen"
                        onClick={() => handleEditProduct(product?._id)}
                        style={{ cursor: "pointer", marginRight: "10px" }}
                      ></i>
                      <i
                        className="fa-solid fa-trash"
                        onClick={() => handleDeleteProduct(product?._id)}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <div className="pagination">{renderPagination()}</div>
      </div>
    </div>
  );
};

export default AdminProducts;
