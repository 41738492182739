import React from "react";
import ContactCard from "../../../Components/ContactCard/ContactCard";
import GetInTouch from "../../../Components/GetInTouch/GetInTouch";
import "./B2BContactUsPage.css";

const B2BContactUsPage = () => {
  return (
    <>
      <div className="get-in-touch-container-c">
        <h1>Get in touch</h1>
        <p className="get-in-touch-container-p">
          Don’t hesitate to contact us for any requests!
        </p>
        <ContactCard />
        <GetInTouch color="#FFE8F2" />
      </div>
    </>
  );
};

export default B2BContactUsPage;
