import React, { useState, useEffect } from "react";
import Button from "../../../Components/Button/Button";
import { useCart } from "../../../contexts/CartContext";
import axios from "axios";
import "./B2CShipping.css";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const B2CShipping = () => {
  const { cart, clearCart } = useCart();
  const token = localStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const [form, setForm] = useState({
    name: "",
    email: "",
    number: "",
    billingAddress: "",
    shippingAddress: "",
    sameAsShipping: false,
  });

  const [subtotal, setSubtotal] = useState(0);
  const [shipping, setShipping] = useState(50);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const calculatedSubtotal = cart.reduce(
      (acc, item) => acc + item.standardPrice * item.quantity,
      0
    );
    setSubtotal(calculatedSubtotal);
    // setTotal(calculatedSubtotal + shipping);
    setTotal(calculatedSubtotal);
  }, [cart, shipping]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm({
      ...form,
      [name]: type === "checkbox" ? checked : value,
      ...(name === "sameAsShipping" && {
        shippingAddress: form.billingAddress,
      }),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !form.name ||
      !form.email ||
      !form.number ||
      !form.billingAddress ||
      !form.shippingAddress
    ) {
      toast.error("Please enter your details");
      return;
    }

    try {
      const razorpayResponse = await axios.post(
        `${baseUrl}/api/order/initiate`,
        {
          total: total * 100,
          currency: "INR",
        }
      );

      const { razorpayOrderId, amount, currency } = razorpayResponse.data;

      const options = {
        key: "rzp_live_arj0ffgOzVbLQB",
        amount,
        currency,
        order_id: razorpayOrderId,
        name: form.name,
        description: "Order Payment",
        handler: async (response) => {
          try {
            const orderData = {
              ...form,
              total,
              cart: cart.map((item) => ({
                productId: item._id,
                name: item.name,
                quantity: item.quantity,
                price: item.standardPrice,
                additionalInfo: item.additionalInfo,
                sku: item.sku,
              })),
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            };

            const saveOrderResponse = await axios.post(
              `${baseUrl}/api/order/saveOrder`,
              orderData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            );

            toast.success("Order placed successfully!");
            clearCart();
            navigate("/b2c-home-page");
          } catch (error) {
            toast.error("Error saving order. Please contact support.");
            console.error("Order saving error:", error);
          }
        },
        prefill: {
          name: form.name,
          email: form.email,
          contact: form.number,
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on("payment.failed", (response) => {
        console.error("Payment failed:", response.error);
        toast.error("Payment failed. Please try again.");
      });
    } catch (error) {
      toast.error("Error initiating payment");
      console.error("Payment initiation error:", error);
    }
  };

  return (
    <>
      <div className="shipping-container">
        <div className="shipping-form-container">
          <ToastContainer />
          <p>Shipping Address</p>
          <form>
            <table>
              <tr>
                <td>
                  <label for="name">Name:</label>
                </td>
                <td className="input-field">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label for="email">Email:</label>
                </td>
                <td className="input-field">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={form.email}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label for="phone">Phone Number:</label>
                </td>
                <td className="input-field">
                  <input
                    type="number"
                    id="number"
                    name="number"
                    value={form.number}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label for="billingAddress">Billing Address:</label>
                </td>
                <td className="input-field">
                  <textarea
                    id="billingAddress"
                    name="billingAddress"
                    value={form.billingAddress}
                    onChange={handleChange}
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <label>
                    Billing address same as shipping address.
                    <input
                      type="checkbox"
                      id="sameAsShipping"
                      name="sameAsShipping"
                      style={{ width: "13vw" }}
                      checked={form.sameAsShipping}
                      onChange={handleChange}
                    />
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="shippingAddress">Shipping Address:</label>
                </td>
                <td className="input-field">
                  <textarea
                    id="shippingAddress"
                    name="shippingAddress"
                    value={
                      form.sameAsShipping
                        ? form.billingAddress
                        : form.shippingAddress
                    }
                    onChange={handleChange}
                    disabled={form.sameAsShipping}
                  ></textarea>
                </td>
              </tr>
            </table>
          </form>
          <div className="shipping-button">
            <Button label="Buy Now" color="#73A9FA" action={handleSubmit} />
          </div>
        </div>
        <div className="shipping-summary">
          <div className="shipping-summary-container">
            <div className="table-container">
              <table className="table">
                <tbody>
                  <tr>
                    <td>Subtotal:</td>
                    <td>₹{subtotal}</td>
                  </tr>
                  {/* <tr>
                    <td>Shipping:</td>
                    <td>₹{shipping}</td>
                  </tr> */}
                </tbody>
              </table>
              <div className="horizontal-line"></div>
              <table className="table">
                <tbody>
                  <tr>
                    <td>Your Total:</td>
                    <td>₹{total}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default B2CShipping;
