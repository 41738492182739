import React, { useContext, useEffect, useState } from "react";
import Baner from "../../../Components/Baner/Baner";
import LatestArrivals from "../../../Components/LatestArrivals/LatestArrivals";
import ProductCategories from "../../../Components/ProductCategories/ProductCategories";
import GetInTouch from "../../../Components/GetInTouch/GetInTouch";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Layout from "../../../Components/Layout/Layout";
import { ProductContext } from "../../../contexts/ProductContext";
import "./B2BHomePage.css";
import OurClient from "../../../Components/OurClient/OurClient";

const B2BHomePage = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { searchTerm } = useContext(ProductContext);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseUrl}/api/banner/getbanner`);
        setBannerData(response.data);
      } catch (error) {
        console.error("Error fetching banners:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBannerData();
  }, [baseUrl]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/order`)
      .then((res) => res.json())
      .then((data) => {
        if (data.mode) {
          setBannerData([]);
        }
      })
      .catch((err) => console.error("Error fetching order:", err));
  }, []);

  return (
    <Layout
      title="B2B | PrimaGifting"
      description="Welcome to the home page."
      favicon="/assets/navLogo.png"
    >
      <Baner />
      <LatestArrivals />
      <ProductCategories />
      <GetInTouch />
      <OurClient />
    </Layout>
  );
};

export default B2BHomePage;
