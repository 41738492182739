import React, { useState } from "react";
import axios from "axios";
import Footer from "../Components/Footer/Footer";
import Nav from "../Components/Nav/Nav";
import "./CSS/BELogin.css";
import { toast, ToastContainer } from "react-toastify";

const BELoginPage = () => {
  console.log("I am in b2e login");

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSendOtp = async () => {
    setErrorMessage("");
    if (!email) {
      toast.error("Email is required");
      return;
    }

    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      await axios.post(`${baseUrl}/api/otp/send-otp`, {
        email: email.toLowerCase(),
      });

      toast.success("OTP sent successfully");
      setIsOtpSent(true);
    } catch (error) {
      const message =
        error.response?.data?.message || "Error sending OTP. Please try again.";
      setErrorMessage(message);
      toast.error(message);
    }
  };

  const handleVerifyOtp = async () => {
    setErrorMessage("");
    if (!otp) {
      toast.error("OTP is required");
      return;
    }

    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.post(`${baseUrl}/api/otp/verify-otp`, {
        email: email.toLowerCase(),
        otp,
      });

      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem("companyToken", token);

        toast.success("OTP verified successfully");
        window.location.href = "/b2e-home-page";
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ||
          "OTP verification failed. Please try again."
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <Nav color="blue" />
      <div className="login-container">
        <h1>Welcome</h1>
        <p>Please register with your business email</p>
        <input
          type="email"
          placeholder="Email"
          className="login-container-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {isOtpSent && (
          <>
            <input
              type="text"
              placeholder="Enter OTP"
              className="login-container-input"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <button className="login-button" onClick={handleVerifyOtp}>
              Verify OTP
            </button>
          </>
        )}
        {!isOtpSent && (
          <button className="login-button" onClick={handleSendOtp}>
            Send OTP
          </button>
        )}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
      <Footer />
    </>
  );
};

export default BELoginPage;
