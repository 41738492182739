import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "axios";
import "./Baner.css";

const Baner = () => {
  const carouselRef = useRef(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getBanner = () => {
      const cachedData = localStorage.getItem("bannerData");
      if (cachedData) {
        setData(JSON.parse(cachedData));
      } else {
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/api/banner/getbanner`)
          .then((res) => {
            setData(res.data);
            localStorage.setItem("bannerData", JSON.stringify(res.data));
          })
          .catch((error) => console.log(error));
      }
    };

    const interval = setInterval(() => {
      if (carouselRef.current) {
        carouselRef.current.increment();
        setIsInitialized(true);
      }
    }, 3000);

    getBanner();
    return () => clearInterval(interval);
  }, []);

  if (data.length === 0) {
    return (
      <div className="shimmer" style={{ width: "100%", marginTop: 0 }}></div>
    );
  }
  return (
    <div className="baner-container">
      <Carousel
        ref={carouselRef}
        showArrows={true}
        autoPlay={false}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        interval={3000}
        transitionTime={1000}
        onClickItem={() => {
          setIsInitialized(true);
        }}
      >
        {data.map((banner, index) => (
          <div key={index} className="baner-slide">
            <img
              src={banner.imagePath}
              alt={banner.title}
              className="baner-image"
              style={{ height: "100%" }}
            />
            <div className="baner-content">
              <p className="baner-title" style={{ color: banner.textColor }}>
                {banner.title}
              </p>
              <p className="baner-subtitle" style={{ color: banner.textColor }}>
                {banner.subtitle}
              </p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Baner;
