import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./B2BProductListBrand.css";
import axios from "axios";
import { useRef } from "react";
import ProductCatalogueCard from "../../../Components/ProductCatalogueCard/ProductCatalogueCard";
import ClipLoader from "react-spinners/ClipLoader";

const B2BProductListBrand = () => {
  const cache = useRef({});
  const [products, setProducts] = useState([]);
  // const [brands, setBrands] = useState([]);
  const [sortOrder, setSortOrder] = useState("latest");
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(9);
  const [selectedBrandId, setSelectedBrandId] = useState(
    "6764149de2d4b6d23a728dd0"
  );
  const [totalPages, setTotalPages] = useState(1);
  const [totalProducts, setTotalProducts] = useState(9);
  const [loading, setLoading] = useState(false);

  const brands = [
    { _id: "675969addfadab29bcc745d3", name: "Acer" },
    { _id: "6764149de2d4b6d23a728dd0", name: "Adidas" },
    { _id: "675969cbdfadab29bcc745d6", name: "Asus" },
    { _id: "6764205ae2d4b6d23a728e15", name: "Awards" },
    { _id: "676406d7e2d4b6d23a728c4b", name: "Benetton" },
    { _id: "6761a342e2d4b6d23a7285d6", name: "Candels, diyas, aromas" },
    { _id: "67640e64e2d4b6d23a728d68", name: "Cantabil" },
    { _id: "676406e3e2d4b6d23a728c4e", name: "Caslay" },
    { _id: "6759697fdfadab29bcc745b0", name: "Dell" },
    { _id: "67629234e2d4b6d23a728941", name: "Diaries" },
    { _id: "676194a3e2d4b6d23a72849c", name: "Ferrero" },
    { _id: "676198ebe2d4b6d23a7284c8", name: "Hamper" },
    { _id: "6759649adfadab29bcc74474", name: "HP" },
    { _id: "67596963dfadab29bcc745ad", name: "Lenovo" },
    { _id: "66c484e9301a40ecfbae2ed5", name: "RAPZ" },
    { _id: "67640d37e2d4b6d23a728d27", name: "Swiss military" },
    { _id: "675d41b6e2d4b6d23a7277ad", name: "Titan" },
    { _id: "67596deedfadab29bcc746ab", name: "Wonderchef" },
    { _id: "6707fc77dcb95f97fdf03e93", name: "ZEBRONICS" },
  ];

  const selectBrand = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem("token");

    const cacheKey = `${selectedBrandId}_${currentPage}`;

    if (cache.current[cacheKey]) {
      const cachedData = cache.current[cacheKey];
      setProducts(cachedData.products);
      setTotalPages(cachedData.totalPages);
      setTotalProducts(cachedData.totalProducts);
      return;
    }

    const config = {
      method: "get",
      url: `${baseUrl}/api/product/brand?brand=${selectedBrandId}&page=${currentPage}&prodType=B2B`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(config);
      const data = {
        products: response.data.products,
        totalPages: response.data.totalPages,
        totalProducts: response.data.totalProducts,
      };
      cache.current[cacheKey] = data;

      setProducts(response.data.products);
      setTotalPages(response.data.totalPages);
      setTotalProducts(response.data.totalProducts);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedBrandId) {
      setCurrentPage(1);
      selectBrand(selectedBrandId);
    }
  }, [selectedBrandId]);

  useEffect(() => {
    if (selectedBrandId) {
      selectBrand(selectedBrandId);
    }
  }, [currentPage]);

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  const sortedProducts = (products.products || []).sort((a, b) => {
    if (sortOrder === "latest") {
      return new Date(b.createdAt) - new Date(a.createdAt);
    } else {
      return new Date(a.createdAt) - new Date(b.createdAt);
    }
  });

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const renderPagination = () => {
    const pages = [];

    pages.push(
      <button key="prev" onClick={handlePrev} disabled={currentPage === 1}>
        Prev
      </button>
    );

    if (currentPage > 1) {
      pages.push(
        <button
          key={currentPage - 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          {currentPage - 1}
        </button>
      );
    }

    pages.push(
      <button key={currentPage} className="active">
        {currentPage}
      </button>
    );

    if (currentPage < totalPages) {
      pages.push(
        <button
          key={currentPage + 1}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          {currentPage + 1}
        </button>
      );
    }

    pages.push(
      <button
        key="next"
        onClick={handleNext}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    );

    return pages;
  };

  return (
    <div className="product-list-container">
      <div className="product-list-title">Product catalogue</div>
      <div className="product-list-showcase">
        <div className="product-list-cat-list-container">
          <h1>Brands</h1>
          {brands.map((brand) => (
            <p
              key={brand._id}
              onClick={() => {
                selectBrand(brand._id);
                setSelectedBrandId(brand._id);
              }}
              className={brand._id === selectedBrandId ? "selected-brand" : ""}
            >
              {brand.name}
            </p>
          ))}
        </div>
        <div className="product-list-cat-container">
          <div className="product-list-selection">
            <select onChange={handleSortOrderChange} value={sortOrder}>
              <option value="latest">Latest</option>
              <option value="oldest">Oldest</option>
            </select>
            <p className="pagination-text">
              Showing {indexOfFirstProduct + 1} to{" "}
              {indexOfFirstProduct + products.length} of {totalProducts} results
            </p>
          </div>
          <div className="product-list-card-container">
            {loading ? (
              <div className="loader-b2b">
                <ClipLoader color={"#000"} loading={loading} size={30} />
              </div>
            ) : products.length < 0 ? (
              <div className="no-data">No Data Found</div>
            ) : (
              products.map((product) => (
                <ProductCatalogueCard key={product._id} product={product} />
              ))
            )}
          </div>
          <div className="pagination">{renderPagination()}</div>
        </div>
      </div>
    </div>
  );
};

export default B2BProductListBrand;
