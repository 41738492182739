import React from "react";
import "./ContactCard.css";

const contactMethods = [
  {
    imgSrc: "/assets/call.svg",
    title: "Call Us",
    subtitle: "Talk to our team",
    contactInfo: "+918450985249",
    link: "tel:+918450985249",
  },
  {
    imgSrc: "/assets/whatsapp.svg",
    title: "Whatsapp",
    subtitle: "Message us",
    contactInfo: "+918450985249",
    link: "https://wa.me/+918450985249",
  },
  {
    imgSrc: "/assets/mail.svg",
    title: "Email",
    subtitle: "Email our team",
    contactInfo: "infosales@techitprima.com",
    link: "mailto:infosales@techitprima.com",
  },
  {
    imgSrc: "/assets/loc.svg",
    title: "Address",
    subtitle:
      "R80, RNA Arcade, 1st Floor, Lokhandwala Complex, Andheri West, Mumbai 400053",
    link: "https://maps.app.goo.gl/eCmx8GHFaNUupUvw6",
  },
];

const ContactCard = () => {
  return (
    <div className="contact-container">
      {contactMethods.map((method, index) => (
        <a
          href={method.link}
          className="contact-card-container"
          key={index}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={method.imgSrc} className="contact-img" alt={method.title} />
          <p className="contact-card-title">{method.title}</p>
          <p className="contact-card-sub-title">{method.subtitle}</p>
          {method.contactInfo && (
            <p className="contact-card-link">{method.contactInfo}</p>
          )}
        </a>
      ))}
    </div>
  );
};

export default ContactCard;
