import React, { useEffect, useState } from "react";
import "./OurClient.css";

const hardcodedBannerData = [
  { image: "/assets/ourClient1.png" },
  { image: "/assets/ourClient2.png" },
  { image: "/assets/ourClient3.png" },
  { image: "/assets/ourClient4.png" },
  { image: "/assets/ourClient5.png" },
  { image: "/assets/ourClient6.png" },
  { image: "/assets/ourClient7.png" },
];

const OurClient = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const duplicatedBannerData = [...hardcodedBannerData, ...hardcodedBannerData];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }, 3000); 
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (currentIndex === hardcodedBannerData.length) {
      const timeout = setTimeout(() => {
        setCurrentIndex(0);
      }, 800); 
      return () => clearTimeout(timeout);
    }
  }, [currentIndex]);

  const visibleCount = isMobile ? 1 : 5;

  return (
    <div className="custom-carousel-container">
      <div
        className="carousel-track"
        style={{
          transform: `translateX(-${(currentIndex % hardcodedBannerData.length) * (100 / visibleCount)}%)`,
          width: `${(duplicatedBannerData.length / visibleCount) * 100}%`,
        }}
      >
        {duplicatedBannerData.map((banner, index) => (
          <div
            key={index}
            className="carousel-item"
            style={isMobile ? { flex: `0 0 ${100 / visibleCount}%` } : {flex: `0 0 ${100 / 8}%`}}
            >
            <img src={banner.image} alt={`Client ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurClient;
