import React, { useEffect, useState } from "react";
import axios from "axios";
import "./AdminQuote.css";

const AdminQuote = () => {
  const [quotes, setQuotes] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseUrl}/api/quote/getQuotes`);
        const reversedQuotes = response.data.data.reverse();
        setQuotes(reversedQuotes);
      } catch (error) {
        console.error("Error fetching quotes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuotes();
  }, []);

  const handleViewClick = (products) => {
    setSelectedProducts(products);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProducts([]);
  };

  if (loading) {
    return (
      <div className="quotes-table-container">
        <h2>Quote</h2>
        <table className="quotes-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Company Name</th>
              <th>Phone Number</th>
              <th>Location</th>
              <th>Email</th>
              <th>Message</th>
              <th>Action</th>
            </tr>
          </thead>
        </table>
        <div className="shimmer"></div>
      </div>
    );
  }

  return (
    <div className="quotes-table-container">
      <h2>Quote</h2>
      <table className="quotes-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Company Name</th>
            <th>Phone Number</th>
            <th>Location</th>
            <th>Email</th>
            <th>Message</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {quotes.map((quote) => (
            <tr key={quote._id}>
              <td>{quote.name}</td>
              <td>{quote.companyName}</td>
              <td>{quote.phoneNumber}</td>
              <td>{quote.location}</td>
              <td>{quote.email}</td>
              <td>{quote.message}</td>
              <td>
                <button
                  className="view-button"
                  onClick={() => handleViewClick(quote.quote)}
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content" style={{ minWidth: "800px" }}>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <h2>Product Details</h2>
            <table className="products-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Stock</th>
                  <th>Brand</th>
                  <th>Price</th>
                  <th>SKU</th>
                </tr>
              </thead>
              <tbody>
                {selectedProducts.map((product) => (
                  <tr key={product._id}>
                    <td>{product.name}</td>
                    <td>{product.shortDis}</td>
                    <td>{product.stock}</td>
                    <td>{product?.brand?.name}</td>
                    <td>₹{product.standardPrice}</td>
                    <td>{product.sku}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminQuote;
